<template>
    <div class="container">
        <div class="row" dir="rtl">
            <div class="col-12 col-lg-9 mt-5">
                <div class="public_health" dir="rtl">
                    <h3 style="font-family: DroidArabic!important;"><span style="color: red; font-size: 35px; font-weight: bold">|</span> دراسات علمية </h3>
                    <p >
                         الصحة هي حالة من اكتمال السلامة بدنياً وعقلياً واجتماعياً، لا مجرد انعدام المرض أو العجز. التمتع بأعلى مستوى من الصحة يمكن بلوغه هو أحد الحقوق الأساسية لكل إنسان، دون تمييز بسبب العنصر أو الدين أو العقيدة السياسية أو الحالة الاقتصادية أو الاجتماعية. صحة جميع الشعوب أمر أساسي لبلوغ السلم والأمن، وهي تعتمد على التعاون الأكمل للأفراد والدول.
                    </p>
                    <div class="row mt-4">
                        <div class="col-12 col-sm-6 col-lg-4 mt-4">
                            <div class="card" style="width: 100%;">
                                <img src="../assets/etudes/aid.png" class="card-img-top" alt="">
                                <div class="card-body">
                                    <h5 class="card-title">عيد الأضحى</h5>
                                    <p class="card-text">تغتنم الوكالة الوطنية للأمن الصحي مناسبة عيد الأضحى لاقتراح دليل لصالح المواطنين، الذي يتعلق ...</p>
                                    <router-link to="/aid-adha" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop" >إقرأ المزيد ...</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 mt-4">
                            <div class="card" style="width: 100%;" >
                                <img src="../assets/etudes/covid.png" class="card-img-top" alt="">
                                <div class="card-body">
                                    <h5 class="card-title">فيروس كورونا</h5>
                                    <p class="card-text">فيروسات كورونا هي عائلة كبيرة من فيروسات الجهاز التنفسي، يسبب بعضها أمراضًا أقل من غيرها ...</p>
                                    <router-link to="/corona" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop">إقرأ المزيد ...</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4 mt-4">
                            <div class="card" style="width: 100%;">
                                <img src="../assets/etudes/sing.png" class="card-img-top" alt="">
                                <div class="card-body">
                                    <h5 class="card-title">جدري القردة</h5>
                                    <p class="card-text">جدري القردة هو مرض فيروسي حيواني المصدر، أي أنه يمكن أن ينتقل من الحيوان إلى البشر  ...</p>
                                    <router-link to="/monkey" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop">إقرأ المزيد ...</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3  mt-5">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-12 mt-lg-5 text-center mb-3 px-2">
                        <div class='widget-content'>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F%25D8%25A7%25D9%2584%25D9%2588%25D9%2583%25D8%25A7%25D9%2584%25D8%25A9-%25D8%25A7%25D9%2584%25D9%2588%25D8%25B7%25D9%2586%25D9%258A%25D8%25A9-%25D9%2584%25D9%2584%25D8%25A3%25D9%2585%25D9%2586-%25D8%25A7%25D9%2584%25D8%25B5%25D8%25AD%25D9%258A-110473111721339&tabs=timeline&width=300&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="300" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4 mb-4" dir="rtl">
            <div class="col-md-8">
                <div class="public_health" dir="rtl">
                    <h3 style="font-family: DroidArabic!important;"><span style="color: red; font-size: 35px; font-weight: bold">|</span> الوكالة</h3>
                    <p style="font-family: DroidArabic!important; line-height: 30px">
                        الوكالة هي مؤسسة مسؤولة على المراقبة الاستراتيجية، التوجيه والتنبيه في كل ما يخص الأمن الصحي. كما أنها مسؤولة، بالتشاور مع الهياكل المعنية، على تطوير وتنفيذ الاستراتيجية الوطنية لتحقيق مهامها.
                        <!--<router-link class="btn btn-success" to="#">إقرأ المزيد ...</router-link>-->
                    </p>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="card mb-3" style="width: 100%;">
                                <img src="../assets/president_agence.jpg" class="card-img-top" alt="">
                                <div class="card-body">
                                    <h5 class="card-title">كلمة الرئيس</h5>
                                    <p class="card-text">الصحة ليست العلاج، إنما تتمثل في حالة سلامة وخلو من الأمراض، من أجل إدامتها لأطول فترة ممكنة وجب رعايتها ...</p>
                                    <router-link to="WordPresident" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop">إقرأ المزيد ...</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card" style="width: 100%;">
                                <img src="../assets/photoMissions.png" class="card-img-top" alt="">
                                <div class="card-body">
                                    <br>
                                    <h5 class="card-title">مهامها</h5>
                                    <p class="card-text">تكلف الوكالة، في إطـــار المـــهام المحددة في المادة  3مـن المـــرسوم الرئاسي رقم 20–158 المؤرخ في  21شوال عام 1441 ...</p>
                                    <router-link to="missions" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop">إقرأ المزيد ...</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-4" style="border-right: 1px solid #008000; ">
                <h3 style="font-family: DroidArabic!important;"><span style="color: red; font-size: 35px; font-weight: bold">|</span> البيئة</h3>
                <p class="card-text" style="font-family: DroidArabic!important;">مجموع الظروف والعناصر المحيطة بنا، وتؤثر البيئة بشكل كبير ومباشر على صحتنا، لهذا تسهر الوكالة على مراقبة وتقييم كل المخاطر المحيطة بنا</p>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-solid fa-recycle" />
                    <a style="margin-right: 20px" href="#">النفايات</a>
                </div>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-solid fa-atom" />
                    <a style="margin-right: 20px" href="#">المواد الكيميائية</a>
                </div>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-solid fa-water" />
                    <a style="margin-right: 20px" href="#">مياه الصرف الصحي</a>
                </div>

                <h3 style="font-family: DroidArabic!important;"><span style="color: red; font-size: 35px; font-weight: bold">|</span> المنتجات الصحية</h3>
                <p class="card-text" style="font-family: DroidArabic!important;">تقوم الوكالة الوطنية للأمن الصحي بمراقبة تقارير الأحداث السلبية والمشاكل الأخرى المتعلقة بالمنتجات الصحية، كما تقوم بتنبيه المهنيين الصحيين والمواطنين عند الحاجة لضمان الاستخدام السليم للمنتجات الصحية</p>
                <div class="heading_environment">
                    <font-awesome-icon icon="fa-solid fa-book-open-reader" />
                    <a style="margin-right: 20px" href="#">الأدوية</a>
                </div>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-brands fa-product-hunt" />
                    <a style="margin-right: 20px" href="#">المنتجات العضوية</a>
                </div>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-solid fa-syringe" />
                    <a style="margin-right: 20px" href="#">اللقاحات والأمصال</a>
                </div>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-solid fa-briefcase-medical" />
                    <a style="margin-right: 20px" href="#">الأجهزة الطبية</a>
                </div>
                <div class="heading_environment">
                    <!--<div class="rec"></div>-->
                    <font-awesome-icon icon="fa-solid fa-paintbrush" />
                    <a style="margin-right: 20px" href="#">مستحضرات التجميل</a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "LatestNews",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }

        }
    }
</script>

<style scoped>
    .heading_environment{
        display: flex;
        align-items: center;
        font-family: DroidArabic;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 20px;
    }
    .heading_environment a{
        text-decoration: none;
        color: #37414D;
        cursor: pointer;
    }
    .heading_environment a:hover{
        color: #004d00;
        text-decoration: underline red;
        /*margin-top: 2px;*/
    }
    .rec{
        height: 6px;
        width: 30px;
        background-color: red;
        /* margin-right: 10px; */
        margin-left: 20px;
    }
    .public_health p{
        font-family: DroidArabic!important;
        line-height: 30px;
        font-size: 14px
    }
    .public_health h5{
        font-family: DroidArabic!important;
    }
</style>