<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" > {{  $t('agency.title') }} > </div>
        </div>
    </div>

    <div class="container" dir="rtl" >
        <div class="title " >النصوص القانونية</div>

        <div class="row mb-5">
            <div class="px-lg-5 ressource mt-5 mb-5 " >
                <div class="container d-flex p-5 ">

                    <div class="card-group row">

                        <div class="cadre col-md-12 col-lg-6 px-lg-5">
                            <div class="cadre__inner">
                                <a href="/pdf/legislation/مرسوم رئاسي رقم 20-158 يتضمن إحداث وكالة وطنية للأمن الصحي.pdf" target="_blank">مرسوم رئاسي رقم 20-158 يتضمن إحداث وكالة وطنية للأمن الصحي</a>
                                <a href="/pdf/legislation/مرسوم رئاسي رقم 20-158 يتضمن إحداث وكالة وطنية للأمن الصحي.pdf"  download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp;</a>
                            </div>
                        </div>

                        <div class="cadre col-md-12 col-lg-6 px-lg-5">
                            <div class="cadre__inner">
                                <a href="/pdf/legislation/Décret présidentiel n°20-158_ Créations de l’Agence Nationale de Sécurité Sanitaire.pdf" target="_blank">Décret présidentiel n°20-158_ Créations de l’Agence Nationale de Sécurité Sanitaire</a>
                                <a href="/pdf/legislation/Décret présidentiel n°20-158_ Créations de l’Agence Nationale de Sécurité Sanitaire.pdf"  download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp;</a>
                            </div>
                        </div>

                        <div class="cadre col-md-12 col-lg-6 px-lg-5">
                            <div class="cadre__inner">
                                <a href="/pdf/legislation/مرسوم رئاسي رقم 20-435 يــوضــح صلاحيات الوكالة الوطنية للأمن الصحي وتنظيمها وسيرها.pdf" target="_blank">مرسوم رئاسي رقم 20-435 يــوضــح صلاحيات الوكالة الوطنية للأمن الصحي وتنظيمها وسيرها</a>
                                <a href="/pdf/legislation/مرسوم رئاسي رقم 20-435 يــوضــح صلاحيات الوكالة الوطنية للأمن الصحي وتنظيمها وسيرها.pdf"  download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp;</a>
                            </div>
                        </div>

                        <div class="cadre col-md-12 col-lg-6 px-lg-5">
                            <div class="cadre__inner">
                                <a href="/pdf/legislation/Décret présidentiel n°20-435_ Attributions, organisation et fonctionnement de l'Agence Natioanle de Sécurité Sanitaire.pdf" target="_blank">Décret présidentiel n° 20-435 précisant les attributions, l’organisation et le fonctionnement de l’Agence Nationale de Sécurité Sanitaire</a>
                                <a href="/pdf/legislation/Décret présidentiel n°20-435_ Attributions, organisation et fonctionnement de l'Agence Natioanle de Sécurité Sanitaire.pdf"  download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp;</a>
                            </div>
                        </div>

                        <div class="cadre col-md-12 col-lg-6  px-lg-5 ">
                            <div class="cadre__inner" >
                                <a href="/pdf/legislation/قـرار يـتـضـمـن تـعـيين أعـضـاء المجلس العلمي و مـجـلـس تـوجـيـه الــوكــالة الوطنية للأمن الصحي.pdf" target="_blank">قـرار يـتـضـمـن تـعـيين أعـضـاء المجلس العلمي و مـجـلـس تـوجـيـه الــوكــالة الوطنية للأمن الصحي</a>
                                <a href="/pdf/legislation/قـرار يـتـضـمـن تـعـيين أعـضـاء المجلس العلمي و مـجـلـس تـوجـيـه الــوكــالة الوطنية للأمن الصحي.pdf" download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp; </a>
                            </div>

                        </div>

                        <div class="cadre col-md-12 col-lg-6 px-lg-5">
                            <div class="cadre__inner">
                                <a href="/pdf/legislation/Arrêté du 21 août 2021 portant nomination des membres du conseil.pdf" target="_blank">Arrêté portant désignation des membres du Conseil Scientifique et du Conseil d'Orientation de l'Agence Nationale de Sécurité Sanitaire</a>
                                <a href="/pdf/legislation/Arrêté du 21 août 2021 portant nomination des membres du conseil.pdf"  download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp;</a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "Legislation"
    }
</script>

<style scoped>
    .centre-title {
        width: 100%;
        max-width: 100%;
        display: flex;
        font-size: var(--xl-font-size);
        font-weight: 700;
        justify-content: center;
        margin-bottom: 20px;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;

    }


    @media (max-width: 460px) {
        .card-group .cadre .cadre__inner{
            height: 120px;
        }
    }
    .card-group .cadre .cadre__inner{
        background-color: #ceded3;
        margin-top: 20px;
        box-shadow: 5px 5px 20px #051a27;
        height: 100px;
        padding-top: 10px;
        border-radius: 4px;
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
    }
    .card-group .cadre .cadre__inner a{
        color: #373737 !important;
        font-size: calc(50 / 40 * 0.7rem);
        text-decoration: none;
        text-shadow: 0 0 5px rgb(251, 255, 255);
    }
    .card-group .cadre .cadre__inner a:hover,.card-group .cadre .cadre__inner i:hover{
        color: #000000 !important;
        font-weight: bold;
        text-shadow: 0 2px 3px rgb(251, 255, 255);
    }
    .card-group .cadre .cadre__inner i{
        color:gray;
        vertical-align: middle;
        box-shadow: 5px 5px 10px #000000;

    }
    .card-group{
        padding-top: auto;
        padding-bottom: auto;
    }

</style>